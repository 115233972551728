class UserDetails {
  constructor() {
      this.userId=null;
      this.userName=null;
      this.userRole=[];
      //this.userToken=null;
      this.OAuthJWT=null;
      this.Authentication=null;
      this.bpId = null;
      this.zeppelinJWT = null;
      this.platformadmin = false;
      this.manager = false;
      this.employee = false;
  }
}

export default (new UserDetails);
