import React from "react";
import { render } from "react-dom";
import { sendAuthorizationRequest, sendTokenRequest } from "../../src/components/Login/actions/sign-in";
import UserDetails from '../userInfo/UserDetails.js';
import UserContext from './UserContext.js';
import { isValidSession, getAllSessionParameters, decodeIdToken } from "../../src/components/Login/actions/session";
import config from 'config';

class UserProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contextState: {
                userDetails: UserDetails
            }
        };
    }
    componentDidMount() {
        // See if there is a valid session.
        // console.log("isValidSession() returns - " + isValidSession())
        // isValidSession() will return true if dev mode is "On"
        if (isValidSession()) {
            if (config.ENVIRONMENT) { //development environment
                UserDetails.userId = config.DEV_USER;
                UserDetails.userName = config.DEV_USERNAME;
                UserDetails.bpId = config.DEV_BPID;
                UserDetails.userRole = config.USER_ROLE; 
                UserDetails.Authentication = "Bearer "+config.TOKEN;
                UserDetails.OAuthJWT = config.TOKEN;   
                // console.log("Dev Environment"); 
                this.setScope(config.USER_ROLE);
            }else{
                const session = getAllSessionParameters();
                // console.log("Prod Environment");
                if (session != null) {
                    let decodeIdToken = JSON.parse(atob(session.ID_TOKEN.split(".")[1]));
                    //console.log(session.ACCESS_TOKEN)
                    UserDetails.userId = decodeIdToken.sub;
                    UserDetails.userName = decodeIdToken.given_name;
                    UserDetails.bpId = decodeIdToken.BPID;
                    UserDetails.userRole = decodeIdToken.groups;
                    UserDetails.Authentication = "Bearer "+session.ACCESS_TOKEN;
                    UserDetails.OAuthJWT = session.ACCESS_TOKEN;
                    //this.setState({ contextState: { UserDetails: UserDetails } })
                    this.setScope(decodeIdToken.groups);
                } else {
                    window.location.href = "https://localhost:3000/login";
                }
            }
        }else{
            //direct access to a secured API with out a valid session will ask for login.
            if (!config.ENVIRONMENT) {
               
                sendAuthorizationRequest();   
               
            }
        }
    }

    setScope(roles) {       
        roles.includes('ROLE_PF_ADMIN') && (UserDetails.platformadmin = true)
        roles.includes('Internal/manager') && (UserDetails.manager = true)
        roles.includes('Internal/employee') && (UserDetails.employee = true)
    }

    //this.setState({contextState: {userDetails : UserDetails }});
    render() {
        return (
            <UserContext.Provider
                value={this.state.contextState}
            >
                {this.props.children}
            </UserContext.Provider>
        )
    }

}
export default UserProvider;